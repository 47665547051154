<template>
    <div class="options">
        <div class="options_content">
            <div class="options_list">
                <button
                    type="button"
                    class="options_el"
                    :class="{ 'active': value.promocode_group_ids.length === 0 }"
                    @click="clearPromocodeGroups"
                >
                    <span>{{ all_text }}</span>
                </button>
                <button
                    v-for="promocode_group in promocode_groups"
                    type="button"
                    class="options_el"
                    :class="{ 'active': value.promocode_group_ids.includes(promocode_group.id) }"
                    :data-count="promocode_group.count"
                    @click="setPromocodeGroup(promocode_group.id)"
                >
                    <i>{{ promocode_group.count }}</i>
                    <span>{{ promocode_group.name }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        promocode_groups: {
            type: Array,
            required: true,
        },
        all_text: {
            type: String,
            required: true,
        },
        more_filters_text: {
            type: String,
            required: true,
        },
    },
    methods: {
        clearOtherFilters() {
            this.value.card_type_ids = [];
            this.value.partner_ids = [];
            this.value.promocode_type_ids = [];
        },
        clearPromocodeGroups() {
            if (this.value.promocode_group_ids.length) {
                this.value.promocode_group_ids = [];
                this.clearOtherFilters();
                this.updateOptions();
            }
        },
        setPromocodeGroup(promocodeGroupId) {
            if (!this.value.promocode_group_ids.includes(promocodeGroupId)) {
                this.value.promocode_group_ids = [promocodeGroupId];
                this.clearOtherFilters();
                this.updateOptions();
            }
        },
        updateOptions() {
            this.$emit('input', this.value);
        },
    },
};
</script>
