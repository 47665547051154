<template>
    <div class="code_el">
        <div
            class="code_el-claimed"
            :style="{ 'background-color': promocode.label_color }"
        >
            <span>
                {{ getLabel() }}
            </span>
        </div>

        <div class="code_el-img">
            <a :href="promocode.url">
                <img
                    :src="promocode.image || promocode.card.logo"
                    loading="lazy"
                    :alt="promocode.card.title"
                />
            </a>
        </div>

        <div class="code_el-rating">
            <a :href="promocode.url">
                {{ promocode.card.rating }}
            </a>
        </div>

        <div class="code_el-name">
            <a :href="promocode.url">
                {{ promocode.name }}
            </a>
        </div>

        <template v-if="promocode.type === 1">
            <div
                class="code_el-main"
                :class="{ code_copied: copied }"
            >
                <span class="code_el-title">{{ promo_code_text }}</span>
                <div
                    class="code_el-val"
                    @click="copyToClipboard"
                >
                    {{ promocode.value }}
                </div>
                <div class="code_el-copy">
                    <span>{{ copy_text }}</span>
                    <span>{{ copied_text }}</span>
                </div>
            </div>
        </template>

        <template v-if="promocode.type === 2">
            <a
                v-if="promocode.button_bonus"
                class="code_el-main code_el-gift"
                :href="promocode.redirect_url"
                :style="{ 'background-color': lightenColor(promocode.button_bonus_color) }"
                rel="nofollow"
                target="_blank"
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path :stroke="promocode.button_bonus_color" d="M12.6667 5.33337H3.33333C2.59695 5.33337 2 5.93033 2 6.66671V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V6.66671C14 5.93033 13.403 5.33337 12.6667 5.33337Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path :stroke="promocode.button_bonus_color" d="M8 3.33338C8 2.93781 7.8827 2.55113 7.66294 2.22223C7.44318 1.89334 7.13082 1.63699 6.76537 1.48562C6.39992 1.33424 5.99778 1.29463 5.60982 1.3718C5.22186 1.44897 4.86549 1.63946 4.58579 1.91916C4.30608 2.19887 4.1156 2.55523 4.03843 2.94319C3.96126 3.33116 4.00087 3.73329 4.15224 4.09874C4.30362 4.46419 4.55996 4.77655 4.88886 4.99631C5.21776 5.21608 5.60444 5.33338 6 5.33338" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path :stroke="promocode.button_bonus_color" d="M10 5.33338C10.3956 5.33338 10.7822 5.21608 11.1111 4.99631C11.44 4.77655 11.6964 4.46419 11.8478 4.09874C11.9991 3.73329 12.0387 3.33116 11.9616 2.94319C11.8844 2.55523 11.6939 2.19887 11.4142 1.91916C11.1345 1.63946 10.7781 1.44897 10.3902 1.3718C10.0022 1.29463 9.60009 1.33424 9.23463 1.48562C8.86918 1.63699 8.55682 1.89334 8.33706 2.22223C8.1173 2.55113 8 2.93781 8 3.33338" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path :stroke="promocode.button_bonus_color" d="M8 3.33337V14.6667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path :stroke="promocode.button_bonus_color" d="M14 10H2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <span class="code_el-title">{{ promocode.button_bonus }}</span>
            </a>

            <div v-else class="code_el-main code_el_disabled">
                <span class="code_el-title">{{ no_code_required_text }}</span>
            </div>
        </template>

        <a
            class="btn btn_special code_el-link out-link"
            :href="promocode.redirect_url"
            target="_blank"
            rel="nofollow"
        >
            {{ promocode.button_partner || visit_site_text }}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        promocode: Object,
        casinos_text: String,
        promo_code_text: String,
        no_code_required_text: String,
        copy_text: String,
        copied_text: String,
        visit_site_text: String,
    },
    data() {
        return {
            is_active: false,
            copied: false,
        };
    },
    watch: {
        'promocode.value': function() {
            this.adjustFontSize();
        }
    },
    mounted() {
        this.adjustFontSize();

        window.addEventListener('resize', this.adjustFontSize);
        window.addEventListener('orientationchange', this.adjustFontSize);

        const copyButtonElement = this.$refs.copyButton;

        if (copyButtonElement) {
            copyButtonElement.addEventListener('mouseenter', this.showTooltip);
            copyButtonElement.addEventListener('mouseleave', this.hideTooltip);
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.adjustFontSize);
        window.removeEventListener('orientationchange', this.adjustFontSize);

        const copyButtonElement = this.$refs.copyButton;

        if (copyButtonElement) {
            copyButtonElement.removeEventListener('mouseenter', this.showTooltip);
            copyButtonElement.removeEventListener('mouseleave', this.hideTooltip);
        }
    },
    methods: {
        getLabel() {
            return this.promocode.label_text || this.casinos_text;
        },
        lightenColor(hex_color) {
            const r = parseInt(hex_color.replace("#", "").substring(0, 2), 16)
            const g = parseInt(hex_color.replace("#", "").substring(2, 4), 16)
            const b = parseInt(hex_color.replace("#", "").substring(4, 6), 16);
            return 'rgba(' + r + ',' + g + ',' + b + ', 0.3)';
        },
        adjustFontSize() {
            this.$nextTick(() => {
                const promocodeElement = this.$refs.promocode;
                const containerElement = this.$refs.container;

                if (!promocodeElement || !containerElement) return;

                let fontSize = 16;
                promocodeElement.style.fontSize = `${fontSize}px`;

                while (promocodeElement.offsetWidth > containerElement.offsetWidth - 10 && fontSize > 8) {
                    fontSize -= 1;
                    promocodeElement.style.fontSize = `${fontSize}px`;
                }
            });
        },
        showTooltip() {
            const tooltipElement = this.$refs.copyButton.lastElementChild;

            tooltipElement.innerHTML = this.copy_text;
            tooltipElement.classList.add('is-show');
        },
        hideTooltip() {
            const tooltipElement = this.$refs.copyButton.lastElementChild;

            tooltipElement.classList.remove('is-show');
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.promocode.value).then(() => {
                this.copied = true;

                setTimeout(() => {
                    this.copied = false;
                }, 3000);

                window.open(this.promocode.redirect_url, '_blank');
            }).catch(err => {
                console.error('Could not copy text:', err);
            });
        },
    },
};
</script>
