<template>
    <aside class="sidebar">
        <div class="filter_wrap">
            <div class="filter_header">
                <span>{{ advanced_filters_text }}</span>
                <button
                    ref="openFilterBtn"
                    type="button"
                    class="filter_header-btn"
                    @click="openFilter"
                >
                    <span>{{ filter_text }} ({{ filterCount }})</span>
                </button>
            </div>
            <form ref="filter" class="filter" :class="{ 'is-open': isFilterOpen }">
                <button
                    type="button"
                    class="filter_close"
                    @click="closeFilter"
                ></button>
                <div class="filter_title">
                    <span>{{ select_promocodes_text }}</span>
                </div>
                <div class="filter_sub-title">
                    <span>{{ advanced_filters_text }}</span>
                </div>
                <div class="filter_content">
                    <promocode-filters-item
                        v-for="filterItem in filterItems"
                        :key="filterItem.field"
                        v-model="filterItem.model"
                        :items="filterItem.items"
                        :field="filterItem.field"
                        :title="filterItem.text"
                        :icon="filterItem.icon"
                        :show_info="filterItem.show_info"
                        @input="updateFilterItem"
                    >
                        {{ filterItem.values }}
                    </promocode-filters-item>
                </div>
                <div class="filter_action">
                    <button
                        class="btn btn_special"
                        @click.prevent="applyFilters"
                    >
                        {{ apply_text }} ({{ filterCount }})
                    </button>
                </div>
            </form>
            <div class="filter_overlay"></div>
        </div>
    </aside>
</template>

<script>
import PromocodeFiltersItem from "./PromocodeFiltersItem";
export default {
    components: {PromocodeFiltersItem},
    props: {
        value: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        advanced_filters_text: {
            type: String,
            required: true,
        },
        filter_text: {
            type: String,
            required: true,
        },
        select_promocodes_text: {
            type: String,
            required: true,
        },
        casino_text: {
            type: String,
            required: true,
        },
        promocode_group_text: {
            type: String,
            required: true,
        },
        promocode_type_text: {
            type: String,
            required: true,
        },
        apply_text: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            activeFilters: [],
            isFilterOpen: false,
        };
    },
    computed: {
        filterCount() {
            let count = 0;
            if (this.value.card_ids.length) {
                count += this.value.card_ids.length;
            }
            if (this.value.promocode_type_ids.length) {
                count += this.value.promocode_type_ids.length;
            }
            return count;
        },
        filterItems() {
            return [
                {
                    model: this.value.card_ids,
                    items: this.filters.cards,
                    field: 'card_ids',
                    text: this.casino_text,
                    icon: 'css/img/svg/game_category.svg',
                    show_info: true,
                },
                {
                    model: this.value.promocode_type_ids,
                    items: this.filters.promocode_types,
                    field: 'promocode_type_ids',
                    text: this.promocode_type_text,
                    icon: 'css/img/svg/filter-fire.svg',
                    show_info: true,
                },
            ];
        },
    },
    created() {
        setTimeout((scope) => {
            const filters = document.getElementsByClassName('filter_box-title');

            if (filters.length) {
                for (const filter of filters) {
                    const filterName = filter.dataset.filterName;

                    filter.addEventListener('click', (event) => {
                        if (this.activeFilters.includes(filterName)) {
                            this.activeFilters = this.activeFilters.filter((filter) => filter !== filterName);
                        } else {
                            this.activeFilters.push(filterName);
                        }
                    });
                }
            }
        }, 100);
    },
    mounted() {
        this.$nextTick(() => {
            document.addEventListener('click', this.handleClickOutside);
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClickOutside(event) {
            if (this.isFilterOpen && !this.$refs.filter.contains(event.target) && !this.$refs.openFilterBtn.contains(event.target)) {
                this.closeFilter();
            }
        },
        openFilter() {
            this.isFilterOpen = !this.isFilterOpen;
        },
        closeFilter() {
            this.isFilterOpen = false;
        },
        applyFilters() {
            this.closeFilter();
        },
        updateFilterItem({field, value}) {
            this.value[field] = value;
            this.$emit('input', this.value);
        },
    },
};
</script>
