import Vue from "vue";
import Auth from './components/Auth'
import Search from './components/Search'
import AddReview from './components/AddReview'
import Games from "./components/Games";
import Casinos from "./components/Casinos";
import CasinoItem from "./components/CasinoItem";
import RatingCasinos from "./components/RatingCasinos";
import RatingCasinoCategories from "./components/RatingCasinoCategories";
import RatingCasinoFilters from "./components/RatingCasinoFilters";
import RatingCasinoFilterItem from "./components/RatingCasinoFilterItem";
import RatingGames from "./components/RatingGames";
import RatingCategoryGames from "./components/RatingCategoryGames";
import Pagination from "./components/Pagination";
import VisitSitePopup from "./components/VisitSitePopup.vue";
import Promocodes from "./components/Promocodes.vue";
import PromocodeItem from "./components/PromocodeItem.vue";
import PromocodeGroups from "./components/PromocodeGroups.vue";
import PromocodeFilters from "./components/PromocodeFilters.vue";
import PromocodeFiltersItem from "./components/PromocodeFiltersItem.vue";
import Like from "./components/Like.vue";

require('./banners');

Vue.component('auth', Auth);
Vue.component('search', Search);
Vue.component('add-review', AddReview);
Vue.component('games', Games);
Vue.component('casinos', Casinos);
Vue.component('casino-item', CasinoItem);
Vue.component('rating-casinos', RatingCasinos);
Vue.component('rating-casino-categories', RatingCasinoCategories);
Vue.component('rating-casino-filters', RatingCasinoFilters);
Vue.component('rating-casino-filter-item', RatingCasinoFilterItem);
Vue.component('rating-games', RatingGames);
Vue.component('rating-category-games', RatingCategoryGames);
Vue.component('promocodes', Promocodes);
Vue.component('promocode-item', PromocodeItem);
Vue.component('promocode-groups', PromocodeGroups);
Vue.component('promocode-filters', PromocodeFilters);
Vue.component('promocode-filters-item', PromocodeFiltersItem);
Vue.component('pagination', Pagination);
Vue.component('visit-site-popup', VisitSitePopup);
Vue.component('like', Like);

if (!!document.getElementById('search')) {
    new Vue({
        el: '#search',
    });
}

if (!!document.getElementById('popup')) {
    new Vue({
        el: '#popup',
    });
}

if (!!document.getElementById('main-page')) {
    new Vue({
        el: '#main-page',
    });
}

if (!!document.getElementById('rating-page')) {
    new Vue({
        el: '#rating-page',
    });
}

if (!!document.getElementById('developer-page')) {
    new Vue({
        el: '#developer-page',
    });
}

if (!!document.getElementById('game-page')) {
    new Vue({
        el: '#game-page',
    });
}

if (!!document.getElementById('rating-game-page')) {
    new Vue({
        el: '#rating-game-page',
    });
}

if (!!document.getElementById('rating-game-category-page')) {
    new Vue({
        el: '#rating-game-category-page',
    });
}

if (!!document.getElementById('promocode-page')) {
    new Vue({
        el: '#promocode-page',
    });
}

if (!!document.getElementById('card-page')) {
    new Vue({
        el: '#card-page',
    })
}
