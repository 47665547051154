<template>
    <div class="box_inner">
        <promocode-groups
            v-model="options"
            :promocode_groups="filters.promocode_groups"
            :all_text="all_text"
            :more_filters_text="more_filters_text"
            @input="updateOptions"
        ></promocode-groups>

        <div class="wrap_content">
            <div class="content">
                <section class="box">
                    <div class="box_inner">
                        <div class="box_content">
                            <div class="code">
                                <div class="code_list">
                                    <promocode-item
                                        v-for="promocode in promocodes"
                                        :key="promocode.id"
                                        :promocode="promocode"
                                        :casinos_text="casinos_text"
                                        :promo_code_text="promo_code_text"
                                        :no_code_required_text="no_code_required_text"
                                        :copy_text="copy_text"
                                        :copied_text="copied_text"
                                        :visit_site_text="visit_site_text"
                                    ></promocode-item>
                                </div>

                                <div
                                    v-if="canLoadMore"
                                    class="box_bottom"
                                >
                                    <button
                                        class="btn btn_clear load-more"
                                        type="button"
                                        @click="loadMore"
                                    >
                                        <span>{{ load_10_more_text }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <promocode-filters
                v-model="options"
                :filters="filters"
                :advanced_filters_text="advanced_filters_text"
                :filter_text="filter_text"
                :select_promocodes_text="select_promocodes_text"
                :casino_text="casino_text"
                :promocode_group_text="promocode_group_text"
                :promocode_type_text="promocode_type_text"
                :apply_text="apply_text"
                @input="updateOptions"
            ></promocode-filters>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        initialData: String,
    },
    data() {
        const initialData = JSON.parse(this.initialData);

        return {
            promocode_index_route: initialData?.promocode_index_route ?? '',
            promocode_filters_route: initialData?.promocode_filters_route ?? '',
            promo_code_text: initialData?.promo_code_text ?? '',
            no_code_required_text: initialData?.no_code_required_text ?? '',
            copy_text: initialData?.copy_text ?? '',
            copied_text: initialData?.copied_text ?? '',
            visit_site_text: initialData?.visit_site_text ?? '',
            load_10_more_text: initialData?.load_10_more_text ?? '',
            all_text: initialData?.all_text ?? '',
            more_filters_text: initialData?.more_filters_text ?? '',
            advanced_filters_text: initialData?.advanced_filters_text ?? '',
            filter_text: initialData?.filter_text ?? '',
            select_promocodes_text: initialData?.select_promocodes_text ?? '',
            casino_text: initialData?.casino_text ?? '',
            promocode_group_text: initialData?.promocode_group_text ?? '',
            promocode_type_text: initialData?.promocode_type_text ?? '',
            apply_text: initialData?.apply_text ?? '',
            casinos_text: initialData?.casinos_text ?? '',
            count_to_show: initialData?.count_to_show ?? 10,
            count_to_paginate: initialData?.count_to_paginate ?? 10,
            orders: initialData?.orders ?? [],
            filters: initialData?.filters ?? {
                selected_cards: [],
                selected_promocode_groups: [],
                selected_promocode_types: [],
                cards: [],
                promocode_groups: [],
                promocode_types: [],
                count: 0,
            },
            promocodes: initialData?.promocodes ?? [],
            options: {
                card_ids: initialData?.filters?.selected_cards ?? [],
                promocode_group_ids: initialData?.filters?.selected_promocode_groups ?? [],
                promocode_type_ids: initialData?.filters?.selected_promocode_types ?? [],
            },
        };
    },
    computed: {
        canLoadMore() {
            return this.promocodes.length && this.promocodes.length < this.filters.count;
        },
    },
    methods: {
        updateOptions() {
            this.promocodes = [];
            this.fetchFilters();
            this.fetchPromocodes();
        },
        getParams(is_filter = false) {
            const params = new URLSearchParams();

            if (this.options.card_ids.length) {
                params.set('card_ids', this.options.card_ids.join(','));
            }

            if (this.options.promocode_group_ids.length) {
                params.set('promocode_group_ids', this.options.promocode_group_ids.join(','));
            }

            if (this.options.promocode_type_ids.length) {
                params.set('promocode_type_ids', this.options.promocode_type_ids.join(','));
            }

            if (!is_filter) {
                params.set('offset', this.promocodes.length);
            }

            if (!is_filter) {
                params.set('limit', this.promocodes.length ? this.count_to_paginate : this.count_to_show);
            }

            return params;
        },
        fetchFilters() {
            const params = this.getParams(true);

            const url = this.promocode_filters_route + (params.size ? '?' + decodeURIComponent(params) : '');

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    this.filters = response;
                })
                .catch(error => console.log(error.message));
        },
        fetchPromocodes() {
            const params = this.getParams();

            const url = this.promocode_index_route + (params.size ? '?' + decodeURIComponent(params) : '');

            const requestOptions = {
                method: 'get',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((response) => {
                    response.data.forEach((promocode) => this.promocodes.push(promocode));
                    if (window.stickySidebar && typeof window.stickySidebar.updateSticky === 'function') {
                        window.stickySidebar.updateSticky();
                    }
                })
                .catch(error => console.log(error.message));
        },
        loadMore() {
            this.fetchPromocodes();
        },
    },
};
</script>
