<template>
    <div
        :class="{
            cards_el: true,
            active: is_active,
        }"
        :style="card.badge ? `border-color: ${card.badge.color};` : ''"
    >
        <div class="cards_badge" :style="`background-color: ${card.badge?.color};`">
            {{ card.badge?.title }}
        </div>
        <div class="cards_main">
            <div class="cards_img">
                <a
                    class="cards_img-inner"
                    :href="card.url"
                >
                    <img
                        loading="lazy"
                        :src="card.logo"
                        :alt="card.title"
                    />
                </a>
            </div>
            <div class="cards_main-info">
                <a
                    class="cards_name"
                    :href="card.url"
                >
                    {{ card.title }}
                </a>

                <div
                    class="cards_star_rating"
                    v-if="card.rating"
                >
                    {{ card.rating }}
                </div>

                <like :card-like="cardLike"></like>

                <template
                    v-if="card.manual_promocode || card.promocode"
                >
                    <div class="cards_code">
                        <span class="cards_code-title">{{ promo_code_text }}</span>
                        <div
                            class="cards_code-val"
                            @click="copyToClipboard(card.manual_promocode || card.promocode?.value)"
                        >
                            {{ card.manual_promocode || card.promocode?.value }}
                        </div>
                        <div class="cards_code-copy">
                            <span>{{ copy_text }}</span>
                            <span>{{ copied_text }}</span>
                        </div>
                    </div>
                </template>
                <template
                    v-else-if="card.manual_ib_link || card.iblink"
                >
                    <a
                        v-if="card.iblink?.button_bonus"
                        class="code_el-main code_el-gift"
                        :href="card.iblink.redirect_url"
                        :style="{ 'background-color': lightenColor(card.iblink.button_bonus_color || '#DE0578') }"
                        rel="nofollow"
                        target="_blank"
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :stroke="card.iblink.button_bonus_color || '#DE0578'" d="M12.6667 5.33337H3.33333C2.59695 5.33337 2 5.93033 2 6.66671V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V6.66671C14 5.93033 13.403 5.33337 12.6667 5.33337Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path :stroke="card.iblink.button_bonus_color || '#DE0578'" d="M8 3.33338C8 2.93781 7.8827 2.55113 7.66294 2.22223C7.44318 1.89334 7.13082 1.63699 6.76537 1.48562C6.39992 1.33424 5.99778 1.29463 5.60982 1.3718C5.22186 1.44897 4.86549 1.63946 4.58579 1.91916C4.30608 2.19887 4.1156 2.55523 4.03843 2.94319C3.96126 3.33116 4.00087 3.73329 4.15224 4.09874C4.30362 4.46419 4.55996 4.77655 4.88886 4.99631C5.21776 5.21608 5.60444 5.33338 6 5.33338" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path :stroke="card.iblink.button_bonus_color || '#DE0578'" d="M10 5.33338C10.3956 5.33338 10.7822 5.21608 11.1111 4.99631C11.44 4.77655 11.6964 4.46419 11.8478 4.09874C11.9991 3.73329 12.0387 3.33116 11.9616 2.94319C11.8844 2.55523 11.6939 2.19887 11.4142 1.91916C11.1345 1.63946 10.7781 1.44897 10.3902 1.3718C10.0022 1.29463 9.60009 1.33424 9.23463 1.48562C8.86918 1.63699 8.55682 1.89334 8.33706 2.22223C8.1173 2.55113 8 2.93781 8 3.33338" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path :stroke="card.iblink.button_bonus_color || '#DE0578'" d="M8 3.33337V14.6667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path :stroke="card.iblink.button_bonus_color || '#DE0578'" d="M14 10H2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <span class="code_el-title">
                            {{ card.iblink.button_bonus }}
                        </span>
                    </a>
                    <div
                        v-else
                        class="cards_code cards_code_disabled"
                    >
                        <div class="cards_code-title">
                            {{ no_code_required_text }}
                        </div>
                    </div>
                </template>
            </div>
            <button
                type="button"
                class="cards_el-more"
                @click="is_active = !is_active"
            >
                <span>{{ details_text }}</span>
            </button>
        </div>
        <div class="cards_info">
            <div class="cards_info-inner">
                <div
                    class="cards_details"
                    v-if="(card.bullets ?? []).length"
                >
                    <div class="cards_details-title">{{ card.header }}</div>
                    <ul>
                        <li v-for="bullet in card.bullets ?? []">
                            {{ bullet.title }}
                        </li>
                    </ul>
                </div>
                <div class="cards_links">
                    <a v-if="card.redirect_link"
                       class="cards_btn out-link"
                       :href="card.redirect_link"
                       target="_blank"
                       rel="nofollow"
                    >
                        <span>
                            {{ visit_site_text }}
                        </span>
                    </a>
                    <a v-else
                       class="cards_btn out-link call-visit-site-popup-js"
                       :href="card.url"
                       data-type="ajax"
                       :data-popup-link="card.popup_link"
                    >
                        <span>
                            {{ visit_site_text }}
                        </span>
                    </a>
                    <a
                        class="cards_link"
                        :href="`${card.url}`"
                    >
                        {{ analysis_text }}
                    </a>
                </div>
            </div>
            <div
                class="cards_loc"
                v-if="card.facts?.regulatedby_text_show"
            >
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image1"
                >
                    <img :src="card.facts.regulatedby_image1" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image2"
                >
                    <img :src="card.facts.regulatedby_image2" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image3"
                >
                    <img :src="card.facts.regulatedby_image3" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-flag"
                    v-if="card.facts?.regulatedby_image4"
                >
                    <img :src="card.facts.regulatedby_image4" alt="regulated by"/>
                </div>
                <div
                    class="cards_loc-info"
                    v-if="card.facts.license_text"
                >
                    {{ card.facts.license_text }}
                </div>
            </div>
        </div>
        <div class="cards_content">
            <div class="cards_box cards_box_games">
                <div class="cards_box-title">{{ available_games_text }}</div>
                <ul class="cards_list">
                    <li
                        class="cards_list-el"
                        v-for="gameCategory in card.game_categories"
                        v-bind="{ 'data-ok': card.active_game_categories.find((activeGameCategory) => activeGameCategory.id === gameCategory.id) }"
                    >
                    <span class="cards_list-img">
                        <img
                            loading="lazy"
                            :src="gameCategory.logo"
                            :alt="gameCategory.name"
                        />
                    </span>
                        <span class="cards_list-title">{{ gameCategory.name }}</span>
                    </li>
                </ul>
            </div>
            <div
                class="cards_box"
                v-if="(card.deposit_methods ?? []).length"
            >
                <div class="cards_box-title">{{ deposit_methods_text }}</div>
                <ul class="cards_pay">
                    <li v-for="deposit_method in card.deposit_methods ?? []">
                        <img
                            loading="lazy"
                            :src="deposit_method.icon"
                            :alt="deposit_method.title"
                        />
                    </li>
                </ul>
            </div>
            <div
                class="cards_box"
                v-if="card.facts?.mindepost_text_show ?? false"
            >
                <div class="cards_box-title">{{ min_deposit_text }}</div>
                <div class="cards_deposit">
                    {{ card.facts?.min_deposit ? '€' + card.facts?.min_deposit : no_deposit_text }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Like from "./Like.vue";

export default {
    components: {Like},
    props: {
        card: Object,
        like: Object,
        promo_code_text: String,
        copy_text: String,
        copied_text: String,
        details_text: String,
        visit_site_text: String,
        analysis_text: String,
        available_games_text: String,
        deposit_methods_text: String,
        min_deposit_text: String,
        no_deposit_text: String,
        no_code_required_text: String,
    },
    data() {
        return {
            is_active: false,
            regulator: this.card?.regulators.length ? this.card?.regulators[0] : null,
            cardLike: this.like,
        };
    },
    watch: {
        like(newVal) {
            this.cardLike = newVal;
        },
    },
    methods: {
        lightenColor(hex_color) {
            const r = parseInt(hex_color.replace("#", "").substring(0, 2), 16)
            const g = parseInt(hex_color.replace("#", "").substring(2, 4), 16)
            const b = parseInt(hex_color.replace("#", "").substring(4, 6), 16);
            return 'rgba(' + r + ',' + g + ',' + b + ', 0.3)';
        },
        copyToClipboard(value) {
            navigator.clipboard.writeText(value).then(() => {
                this.copied = true;

                setTimeout(() => {
                    this.copied = false;
                }, 3000);

                window.open(this.card.promocode.redirect_url, '_blank');
            }).catch(err => {
                console.error('Could not copy text:', err);
            });
        },
    },
};
</script>
