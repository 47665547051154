<template>
    <aside class="sidebar">
        <div class="filter_wrap">
            <div class="filter_header">
                <span>{{ advanced_filters_text }}</span>
                <button
                    ref="openFilterBtn"
                    type="button"
                    class="filter_header-btn"
                    @click="openFilter"
                >
                    <span>{{ filter_text }} ({{ filterCount }})</span>
                </button>
            </div>
            <form ref="filter" class="filter" :class="{ 'is-open': isFilterOpen }">
                <button
                    type="button"
                    class="filter_close"
                    @click="closeFilter"
                ></button>
                <div class="filter_title">
                    <span>{{ select_casinos_text }}</span>
                </div>
                <div class="filter_sub-title">
                    <span>{{ advanced_filters_text }}</span>
                </div>
                <div class="filter_content">
                    <rating-casino-filter-item
                        v-for="filterItem in filterItems"
                        :key="filterItem.field"
                        v-model="filterItem.model"
                        :items="filterItem.items"
                        :field="filterItem.field"
                        :title="filterItem.text"
                        :icon="filterItem.icon"
                        :show_info="filterItem.show_info"
                        @input="updateFilterItem"
                    >
                        {{ filterItem.values }}
                    </rating-casino-filter-item>
                </div>
                <div class="filter_action">
                    <button
                        class="btn btn_special"
                        @click.prevent="applyFilters"
                    >
                        {{ apply_text }} ({{ filterCount }})
                    </button>
                </div>
            </form>
            <div class="filter_overlay"></div>
        </div>
    </aside>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        advanced_filters_text: {
            type: String,
            required: true,
        },
        filter_text: {
            type: String,
            required: true,
        },
        select_casinos_text: {
            type: String,
            required: true,
        },
        game_categories_text: {
            type: String,
            required: true,
        },
        game_developers_text: {
            type: String,
            required: true,
        },
        payment_methods_text: {
            type: String,
            required: true,
        },
        regulators_text: {
            type: String,
            required: true,
        },
        min_deposits_text: {
            type: String,
            required: true,
        },
        apply_text: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            activeFilters: [],
            isFilterOpen: false,
        };
    },
    computed: {
        filterCount() {
            let count = 0;
            if (this.value.game_category_ids.length) {
                count++;
            }
            if (this.value.game_developer_ids.length) {
                count++;
            }
            if (this.value.payment_method_ids.length) {
                count++;
            }
            if (this.value.regulator_ids.length) {
                count++;
            }
            if (this.value.min_deposit_ids.length) {
                count++;
            }
            return count;
        },
        filterItems() {
            return [
                {
                    model: this.value.game_category_ids,
                    items: this.filters.game_categories,
                    field: 'game_category_ids',
                    text: this.game_categories_text,
                    icon: 'css/img/svg/game_category.svg',
                    show_info: true,
                },
                {
                    model: this.value.game_developer_ids,
                    items: this.filters.game_developers,
                    field: 'game_developer_ids',
                    text: this.game_developers_text,
                    icon: 'css/img/svg/game_developer.svg',
                    show_info: true,
                },
                {
                    model: this.value.payment_method_ids,
                    items: this.filters.payment_methods,
                    field: 'payment_method_ids',
                    text: this.payment_methods_text,
                    icon: 'css/img/svg/payment_method.svg',
                    show_info: true,
                },
                {
                    model: this.value.regulator_ids,
                    items: this.filters.regulators,
                    field: 'regulator_ids',
                    text: this.regulators_text,
                    icon: 'css/img/svg/regulator.svg',
                    show_info: true,
                },
                {
                    model: this.value.min_deposit_ids,
                    items: this.filters.min_deposits,
                    field: 'min_deposit_ids',
                    text: this.min_deposits_text,
                    icon: 'css/img/svg/min_deposit.svg',
                    show_info: false,
                },
            ];
        },
    },
    mounted() {
        this.$nextTick(() => {
            document.addEventListener('click', this.handleClickOutside);
        });
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleClickOutside(event) {
            if (this.isFilterOpen && !this.$refs.filter.contains(event.target) && !this.$refs.openFilterBtn.contains(event.target)) {
                this.closeFilter();
            }
        },
        openFilter() {
            this.isFilterOpen = !this.isFilterOpen;
        },
        closeFilter() {
            this.isFilterOpen = false;
        },
        applyFilters() {
            this.closeFilter();
        },
        updateFilterItem({field, value}) {
            this.value[field] = value;
            this.$emit('input', this.value);
        },
    },
};
</script>
