<template>
    <div
         class="filter_box"
         :class="{ 'active': is_active} "
    >
        <div class="filter_box-header">
            <div class="filter_box-icon">
                <img :src="icon" :alt="`${title} icon`" loading="lazy" />
            </div>
            <div
                class="filter_box-title"
                @click="is_active = !is_active"
            >
                {{ title }}
            </div>
        </div>
        <div class="filter_box-content">
            <div
                v-for="item in items"
                class="filter_ch"
            >
                <input
                    type="checkbox"
                    :id="`${key}_item_${item.id}`"
                    v-model="options"
                    :value="item.id"
                    @change="updateValues"
                />
                <label :for="`${key}_item_${item.id}`">
                    <span>{{ item.name }}</span>
                    <span class="filter_ch-count">{{ item.count }}</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        field: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        show_info: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            key: (Math.random() + 1).toString(36).substring(7),
            is_active: false,
            options: JSON.parse(JSON.stringify(this.value)),
        };
    },
    computed: {
        filterValues() {
            return this.items.slice(0, 3).map((value) => value.name).join(', ') + (this.items.length > 3 ? '...' : '');
        }
    },
    watch : {
        value(newValue) {
            this.options = newValue;
        },
        is_active(newValue) {
            setTimeout(() => {
                if (window.stickySidebar && typeof window.stickySidebar.updateSticky === 'function') {
                    window.stickySidebar.updateSticky();
                }
            }, 300);
        }
    },
    methods: {
        updateValues() {
            this.$emit('input', {
                field: this.field,
                value: this.options,
            });
        },
    },
};
</script>
